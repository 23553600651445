import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ActionContext from 'src/components/action-context';
import MaterialBatchSuccessCardDetails from 'src/components/material-batch-success-card-details';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS, PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import routes from 'src/utils/routes';
import { formatConvertedUnits } from 'src/utils/ui';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { formatDynamicPercentage, getSliderStep } from 'src/utils/validation';

import ActionPage from './_action-wrapper';


const Sieve = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [batch, setBatch] = useState(undefined);
  const [batchPermanentContainerData, setBatchPermanentContainerData] = useState({
    container: null,
    location: null,
    subLocation: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();

  const { quantity: convertedBatchQuantity, units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (batch) {
        return convertToUserUnits(batch.quantity, batch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [batch]);


  const {
    selectedAmountBase: remainingQuantity,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
  } = useSelectedAmountHandler(
    batch?.quantity || 0,
    batch?.units || '',
    convertedBatchQuantity,
    convertedBatchUnits
  );

  const isEmptySieveValue = remainingQuantity === undefined || remainingQuantity === '';
  const isIncorrectSieveValue = isNaN(remainingQuantity)
    || remainingQuantity > batch?.quantity;

  const {
    containerActionData,
  } = useContext(ActionContext);

  const resetSieveBatchIntoPermanentContainerState = () => {
    // Reset the context state to its initial state
    containerActionData.resetActionContainerState();
  };

  const fetchInitialData = async () => {
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      if (batch && batch.containers.length === 1) {
        /* We have only one container attached to the batch,
         and we need to fetch the container to check if it is disposable or not. */

        const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(batch.containers[0])}/`).json();

        /* Only if the Container is not disposable and has the same batch - we can consider it suitable to
           sieve the batch amount to this container */

        if (!permanentContainer?.disposable) {
          const [permanentContainerLocation, permanentContainerSubLocation] = await Promise.all([
            api.get(`${API_RESOURCES.LOCATION}/${getUuid(permanentContainer.location)}/`).then(response => response.json()),
            api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(permanentContainer.sub_location)}/`).then(response => response.json()),
          ]);

          setBatchPermanentContainerData({
            container: permanentContainer,
            location: permanentContainerLocation,
            subLocation: permanentContainerSubLocation,
          });
        }

      }
      setBatch(batch);
    } catch (error) {
      setError(error.name);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => void fetchInitialData(), []);

  const onActionSieve = async (batchUri, remainingQuantity) => {
    const payload = {
      /* eslint-disable camelcase */
      metadata: {
        remaining_quantity: remainingQuantity,
      },
      action_type: MATERIAL_BATCH_ACTIONS.SIEVE,
      source_batch: batchUri,
    };

    setSubmitting(true);
    await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  const sieveIntoSameContainer = async (batchUri, remainingQuantity) => {
    try {
      await onActionSieve(batchUri, remainingQuantity);

      const containerizeActionPayload = [{
        uri: batchPermanentContainerData.container.uri,
        quantity: remainingQuantity,
      }];

      const containerizePayload = {
        action_type: MATERIAL_BATCH_ACTIONS.CONTAINERIZE_BATCH,
        source_batch: batchUri,
        metadata: {
          containers: containerizeActionPayload,
        },
      };

      await api.post('material-batch-action/', {
        json: containerizePayload,
      });

    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchUri) },
      { action: MATERIAL_BATCH_ACTIONS.SIEVE, permanentContainerUri: batchPermanentContainerData.container.uri }));

    return new Promise(() => {
    });
  };

  const sieveWithoutContainer = async (batchUri, remainingQuantity) => {
    try {
      await onActionSieve(batchUri, remainingQuantity);
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchUri) },
      { action: MATERIAL_BATCH_ACTIONS.SIEVE }));

    return new Promise(() => {
    });
  };

  const remainingPercentage = formatDynamicPercentage(remainingQuantity, batch?.quantity);

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.SIEVE}
      isLoading={isLoading}
    >
      <div className="alert alert-info" role="alert">
        <b>
          <span className="mb15 d-block">You are sieving batch {getShortUuid(batch?.uri)}.</span>
          Please record the amount of material remaining after completing the sieve action.
          After being sieved, the batch will be marked as suitable for loading and blending with other batches.
        </b>
      </div>
      <div className="alert">
        <div>
          &nbsp;
          <input
            name="remainingQuantity"
            min="0"
            className="sieve-input-field"
            step={getSliderStep(convertedBatchQuantity)}
            max={convertedBatchQuantity}
            type="number"
            placeholder="Enter Remaining Quantity"
            value={selectedAmountConverted}
            onChange={handleSelectedAmountChange}
          />
          &nbsp;
          <span>{convertedBatchUnits}
            <span className={isIncorrectSieveValue ? 'text-danger' : ''}>&nbsp;({remainingPercentage || 0}%)</span>
          </span>
          {
            isIncorrectSieveValue && (
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="sieve-value-exceeded">
                    Remaining sieve quantity exceeds the batch quantity
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon icon={faTriangleExclamation} color="#FFC008" className="spacer-left" />
              </OverlayTrigger>
            )
          }
        </div>
        <div className="d-flex align-items-center justify-content-center mt15">
          <div>
            <div className="mb5">0 ({batch?.units})</div>
            <div>{isConverted && formatConvertedUnits(0, convertedBatchUnits)}</div>
          </div>
          <div className="position-relative">
            <RangeInputConversionTooltip
              className="rangeInputAbsolute"
              visible={isConverted} defaultUnits={batch?.units || ''}
              convertedUnits={convertedBatchUnits} />
            &nbsp;
            <input
              name="remainingQuantityRange"
              min="0"
              step="0.01"
              max={batch?.quantity}
              type="range"
              value={remainingQuantity}
              onChange={(e) => handleRangeChange(e.target.value)}
            /> &nbsp;
          </div>
          <div>
            <div className="mb5">{batch?.quantity} ({batch?.units})</div>
            <div>{isConverted && formatConvertedUnits(convertedBatchQuantity, convertedBatchUnits)}</div>
          </div>
        </div>
      </div>

      {
        batchPermanentContainerData.container && (
          <>
            <p className="mb15"><strong>Current container:</strong></p>
            <MaterialBatchSuccessCardDetails
              permanentContainer={batchPermanentContainerData.container}
              batch={batch}
              customQuantity={batchPermanentContainerData.quantity}
              customLocation={batchPermanentContainerData.location?.name}
              subLocation={batchPermanentContainerData.subLocation?.name}
              customMainStyle={{ marginTop: '0' }}
            />
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-block"
              disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
              onClick={() => sieveIntoSameContainer(batch.uri, remainingQuantity)}
            >
              Sieve into current container
            </button>
          </>
        )
      }

      <Link
        to={getRouteURI(routes.scan,
          {},
          {
            'material-batch': batch?.uri,
            entity: API_RESOURCES.MATERIAL_CONTAINER,
            action: PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH,
            sieveQuantity: remainingQuantity,
            initialSieveAction: true,
          })}
        className="link-btn"
      >
        <button
          type="button"
          disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
          className="btn btn-lg btn-secondary btn-block"
          onClick={resetSieveBatchIntoPermanentContainerState}
        >
          Sieve into another container
        </button>
      </Link>

      <button
        type="submit"
        className="btn btn-lg btn-light btn-block link-btn"
        disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
        onClick={() => sieveWithoutContainer(batch.uri, remainingQuantity)}
      >
        Sieve without container
      </button>
      <Link to={`/material-container?batch=${batchUUID}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </ActionPage>
  );
};

Sieve.propTypes = {
  user: userPropType,
};

Sieve.defaultProps = {
  user: null,
};

export default Sieve;
