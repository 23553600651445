import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from 'src/utils/routes';
import { isRouteAllowed } from 'src/utils/url';

const FloatingScanButton = ({ onPrintQRCodesClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handlePrintClick = () => {
    if (onPrintQRCodesClick) {
      onPrintQRCodesClick();
    }
  };

  const { pathname } = location;

  const allowedRoutesForActionBtn = [
    routes.materialContainer,
    routes.permanentContainer,
    routes.printerMaterial,
    routes.materialContainerDetails,
    routes.permanentContainerDetails,
  ];

  const isActionButtonVisible = useMemo(
    () => isRouteAllowed(pathname, allowedRoutesForActionBtn),
    [pathname, allowedRoutesForActionBtn]
  );

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const stackContainerVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
    },
    exit: { opacity: 0, scale: 0 },
  };

  const qrCodeBtnVariant = {
    ...buttonVariants,
    visible: {
      ...buttonVariants.visible,
      transition: { delay: 0.1 },
    },
  };

  const printBtnVariant = {
    ...buttonVariants,
    visible: {
      ...buttonVariants.visible,
      transition: { delay: 0.2 },
    },
  };

  const onPrintClick = () => {
    handlePrintClick();
    setIsOpen(false);
  };

  return isActionButtonVisible ? (
    <div className='floating-button-container'>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className='floating-button-stack'
            initial='hidden'
            animate='visible'
            exit='exit'
            variants={stackContainerVariants}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className='floating-button'
              initial='hidden'
              animate='visible'
              exit='exit'
              variants={printBtnVariant}
              transition={{ duration: 0.3 }}
              onClick={onPrintClick}
            >
              <FontAwesomeIcon icon={faPrint} />
            </motion.div>
            <motion.div
              className='floating-button'
              initial='hidden'
              animate='visible'
              exit='exit'
              variants={qrCodeBtnVariant}
              transition={{ duration: 0.3 }}
            >
              <Link to={routes.scan}>
                <FontAwesomeIcon icon={faQrcode} />
              </Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className={`floating-button ${isOpen ? 'close' : ''}`} onClick={toggleOpen}>
        <FontAwesomeIcon icon={isOpen ? faXmark : faQrcode} />
      </div>
    </div>
  ) : (
    <Link to={routes.scan}>
      <button type='button' className='btn text-reset text-white scan-btn' aria-label='New Scan'>
        <FontAwesomeIcon icon={faQrcode} color='#000' />
      </button>
    </Link>
  );
};

FloatingScanButton.defaultProps = {
  onPrintQRCodesClick: () => null,
};

FloatingScanButton.propTypes = {
  onPrintQRCodesClick: PropTypes.func,
};

export default FloatingScanButton;
