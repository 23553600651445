import { faBoxOpen, faCheckCircle, faTrashAlt, faVials } from '@fortawesome/pro-regular-svg-icons';
import { faRecycle, faWrench } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import NotFound from 'src/components/not-found';
import { ToolingStockCardPreview } from 'src/components/ToolingStockCard';
import ActionForm from 'src/pages/tool/[uuid]/action-form';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { apiWithContext } from 'src/utils/api';
import {
  API_RESOURCES,
  TOOLING_STOCK_ACTION_SUCCESS_MESSAGES,
  TOOLING_STOCK_ACTION_TYPES, TOOLING_STOCK_ACTION_VERBOSE, TOOLING_STOCK_ALLOWED_ACTIONS, TOOLING_STOCK_STATUSES,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';


const Tool = ({ user }) => {
  const { uuid } = useParams();

  const [tool, setTool] = useState();
  const [location, setLocation] = useState();
  const [subLocation, setSubLocation] = useState();
  const [type, setType] = useState();
  const [actionPanelError, setActionPanelError] = useState(null);

  const {
    openActionPanel,
    closeActionPanel,
    setActionPanelProps,
  } = useActionPanelStore();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetchData = async (actionPanelReFetch, shouldReFetchLocation) => {
    if (!uuid) return;
    // Show loading only if it is the first time fetching
    setLoading(!actionPanelReFetch);
    const api = apiWithContext();
    try {
      // Fetch tooling stock data
      const tool = await api.get(`${API_RESOURCES.TOOLING_STOCK}/${uuid}`).json();

      if (!tool) {
        return null;
      }
      setTool(tool);

      const promises = [
        // Skip fetching Tooling Type if we need to re-fetch the base data only
        !actionPanelReFetch && api.get(`${API_RESOURCES.TOOLING_TYPE}/${getUuid(tool.type)}/`).json(),
      ];
      // Fetch Location only on the first fetch or if we need to re-fetch it after the particular action
      if ((!actionPanelReFetch && tool.location) || (actionPanelReFetch && shouldReFetchLocation)) {
        promises.push(api.get(`${API_RESOURCES.LOCATION}/${getUuid(tool.location)}/`).json());
      }
      // Fetch Sub-Location only on the first fetch or if we need to re-fetch it after the particular action
      if ((!actionPanelReFetch && tool.sub_location) || (actionPanelReFetch && shouldReFetchLocation && tool.sub_location)) {
        promises.push(
          api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(tool.sub_location)}/`).json()
        );
      }
      // Fetch tooling stock location, sub-location, and tooling type in parallel
      const [
        type,
        location,
        subLocation,
      ] = await Promise.all(promises);

      // Set Location data on the first fetch or if we need to re-fetch it after the particular action
      if (!actionPanelReFetch || (actionPanelReFetch && shouldReFetchLocation)) {
        setLocation(location);
        setSubLocation(subLocation);
      }
      // Skip setting Tooling Type if we need to re-fetch the base data only
      if (!actionPanelReFetch) {
        setType(type);
      }
    } catch (error) {
      console.error(error);
      // Set either the Action Panel error or the Page error
      setActionPanelProps({
        isSubmitting: false,
        errorMessage: error.message,
      });
      actionPanelError ? setActionPanelError(error.message) : setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [uuid]);

  const getMetadata = (form, action) => {
    switch (action) {
      case TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE: // fallthrough
      case TOOLING_STOCK_ACTION_TYPES.RELOCATE:
        return {
          // eslint-disable-next-line camelcase
          destination_location: form.location,
          // eslint-disable-next-line camelcase
          destination_sub_location: form.subLocation === '' ? null : form.subLocation,
        };

      case TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS:
        return {
          // eslint-disable-next-line camelcase
          test_results: form.notes,
        };

      case TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH:
        return {
          // eslint-disable-next-line camelcase
          test_data: form.notes,
        };
    }
    return {};
  };

  const handleSubmit = async (form, action) => {
    if (!form) return;
    setActionPanelProps({ isSubmitting: true });
    const api = apiWithContext();
    try {
      const payload = {
        metadata: getMetadata(form, action),
        // eslint-disable-next-line camelcase
        tooling_stock: tool.uri,
        // eslint-disable-next-line camelcase
        action_type: action,
      };

      if (action !== TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH &&
        action !== TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS) {
        payload.notes = form.notes;
      }

      await api.post(`${API_RESOURCES.TOOLING_STOCK_ACTION}/`, {
        json: payload,
      }).json();
      const shouldReFetchLocation = [
        TOOLING_STOCK_ACTION_TYPES.RELOCATE,
        TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE].includes(action);
      await fetchData(true, shouldReFetchLocation);
      setActionPanelProps({
        isSubmitting: false,
        title: 'Tool Action Complete',
        successMessage: TOOLING_STOCK_ACTION_SUCCESS_MESSAGES[action],
      });
    } catch (error) {
      console.error(error);
      setActionPanelProps({
        isSubmitting: false,
        errorMessage: error.message,
      });
    } finally {
      setActionPanelProps({ isSubmitting: false });
    }
  };

  const handleSetCurrentAction = action => {
    openActionPanel({
      title: TOOLING_STOCK_ACTION_VERBOSE[action],
      isSubmitting: false,
      successMessage: '',
      errorMessage: '',
      content: (
        <ActionForm
          tool={tool}
          action={action}
          handleSubmit={handleSubmit}
          closePanel={closeActionPanel}
        />
      ),
    });
  };

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={uuid} />
        </main>
      </>
    );
  }

  if (loading || !tool) {
    return (
      <>
        <Header title="Loading Tool..." user={user} />
        <main role="main" className="text-center">
          <div className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
          Loading...
        </main>
      </>
    );
  }

  const renderActionButtons = () => {
    if (!tool || !tool.status) return null;
    const actions = TOOLING_STOCK_ALLOWED_ACTIONS[tool.status] || [];

    return actions.map(actionType => {
      const iconProps = {
        [TOOLING_STOCK_ACTION_TYPES.RELOCATE]: { icon: faBoxOpen, label: 'Relocate' },
        [TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS]: { icon: faVials, label: 'Tests' },
        [TOOLING_STOCK_ACTION_TYPES.DISCARD]: { icon: faTrashAlt, label: 'Discard' },
        [TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE]: { icon: faCheckCircle, label: 'Ready For Use' },
        [TOOLING_STOCK_ACTION_TYPES.MARK_NEEDS_REFURBISH]: { icon: faWrench, label: 'Needs Refurbish' },
        [TOOLING_STOCK_ACTION_TYPES.COMPLETE_USE]: { icon: faCheckCircle, label: 'Complete Use' },
        [TOOLING_STOCK_ACTION_TYPES.SEND_TO_REFURBISH]: { icon: faRecycle, label: 'Refurbish' },
        [TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH]: { icon: faCheckCircle, label: 'Complete Refurbish' },
        [TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE]: { icon: faBoxOpen, label: 'Put Into Use' },
      }[actionType];

      if (actionType === TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE
        && tool.status !== TOOLING_STOCK_STATUSES.IDLE) return null;

      const scanRunUri = getRouteURI(routes.scan,
        {},
        {
          toolUUID: getUuid(tool.uri),
          action: TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE,
          entity: API_RESOURCES.SUMMARY,
        });

      return (
        <div key={actionType} className="batch-option-button">
          <Link to={actionType === TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE ? scanRunUri : '#'}>
            <button
              type="button"
              className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
              onClick={() => actionType === TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE
                ? () => {
                }
                : handleSetCurrentAction(actionType)}
            >
              <FontAwesomeIcon icon={iconProps.icon} size="3x" className="d-block m-auto" />
              {iconProps.label}
            </button>
          </Link>
        </div>
      );
    });
  };


  return (
    <>
      <Header title={tool?.name} user={user} />
      <main role="main" className="text-center">
        <ToolingStockCardPreview
          expanded toolingStock={tool} toolingType={type}
          location={location?.name}
          subLocation={subLocation?.name} />
        <div className="batch-options-button-wrapper">
          {renderActionButtons()}
        </div>
      </main>
    </>
  );
};

Tool.propTypes = {
  user: userPropType.isRequired,
};

export default Tool;
