import convert from 'convert-units';
import {
  CONVERT_UNITS_MAP,
  IMPERIAL_DEFAULT_UNITS,
  IMPERIAL_UNITS,
  LOCALSTORAGE_KEYS,
  MATERIAL_UNITS,
  MEASUREMENT_SYSTEM,
  METRIC_DEFAULT_UNITS,
  METRIC_UNITS,
  VOLUME_UNITS,
  WEIGHT_UNITS,
} from 'src/utils/constants';

/**
 * Formats a given quantity to two decimal places.
 *
 * @param {number} quantity - The number to format.
 * @param {boolean} [roundUp=false] - Whether to round up the quantity.
 * @returns {number} The formatted number with two decimal places.
 */
export const formatTwoDecimalsNumber = (quantity, roundUp = false) => {
  // If quantity is undefined, null, or not a number, return 0
  if (quantity === undefined || quantity === null || isNaN(quantity)) return 0;

  // Ensure the quantity is of type number
  if (typeof quantity !== 'number') return 0;

  // If rounding up is requested, use Math.ceil after scaling
  if (roundUp) {
    return Math.ceil(quantity * 100) / 100;
  }

  // Otherwise, round to two decimal places using toFixed and parseFloat
  return parseFloat(quantity.toFixed(2));
};

/**
 * Helper function to perform unit conversion with optional post-processing.
 *
 * @param {number} quantity - The quantity to convert.
 * @param {string} fromUnit - The current unit.
 * @param {string} toUnit - The target unit.
 * @param {object} [options] - Optional parameters for post-processing.
 * @param {function} [options.postProcess] - A function to process the converted quantity.
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
const convertUnits = (quantity, fromUnit, toUnit, options = {}) => {
  const { postProcess } = options;

  // If both units are the same, no conversion needed
  if (fromUnit === toUnit) {
    // Format the quantity to two decimals
    const formattedQuantity = formatTwoDecimalsNumber(quantity);

    // If a postProcess function is provided, apply it to the formatted quantity
    const finalQuantity = postProcess ? postProcess(formattedQuantity) : formattedQuantity;

    // Return the result without indicating a conversion
    return { quantity: finalQuantity, units: toUnit, isConverted: false };
  }

  try {
    // Perform the conversion using the 'convert-units' library
    const converted = convert(quantity)
      .from(CONVERT_UNITS_MAP[fromUnit]) // Map the fromUnit to the library's expected unit
      .to(CONVERT_UNITS_MAP[toUnit]); // Map the toUnit to the library's expected unit

    // Format the converted quantity to two decimals
    const formattedQuantity = formatTwoDecimalsNumber(converted);

    // If a postProcess function is provided, apply it to the formatted quantity
    const finalQuantity = postProcess ? postProcess(formattedQuantity) : formattedQuantity;

    // Return the converted quantity with the target unit and a flag indicating successful conversion
    return { quantity: finalQuantity, units: toUnit, isConverted: true };
  } catch (error) {
    // Log a warning if the conversion fails (e.g., unsupported units)
    console.warn(`Conversion error from ${fromUnit} to ${toUnit}:`, error);

    // Return the original quantity formatted, keeping the original unit and indicating no conversion
    return { quantity: formatTwoDecimalsNumber(quantity), units: fromUnit, isConverted: false };
  }
};

/**
 * Converts a given quantity from one unit to another using convert-units library.
 *
 * @param {number} quantity - The quantity to be converted.
 * @param {string} currentUnit - The unit of the given quantity (e.g., "LB", "KG", "CM3").
 * @param {string} targetVolumeUnit - The target volume unit for conversion (e.g., "L", "ML").
 * @param {string} targetWeightUnit - The target weight unit for conversion (e.g., "LB", "GRAM").
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
export const performConversion = (quantity, currentUnit, targetVolumeUnit, targetWeightUnit) => {
  // Check if the current unit is a weight unit and a target weight unit is provided
  if (WEIGHT_UNITS.includes(currentUnit) && targetWeightUnit) {
    // Perform the conversion from currentWeightUnit to targetWeightUnit
    return convertUnits(quantity, currentUnit, targetWeightUnit);
  }

  // Check if the current unit is a volume unit and a target volume unit is provided
  if (VOLUME_UNITS.includes(currentUnit) && targetVolumeUnit) {
    // Perform the conversion from currentVolumeUnit to targetVolumeUnit
    return convertUnits(quantity, currentUnit, targetVolumeUnit);
  }

  // If no applicable conversion is found, return the original quantity and unit without conversion
  return { quantity: formatTwoDecimalsNumber(quantity), units: currentUnit, isConverted: false };
};

/**
 * Retrieves unit settings from localStorage.
 *
 * @returns {object} The unit settings.
 */
export const getUnitsSettings = () => {
  // Attempt to retrieve the units settings from localStorage using the predefined key
  const settings = window.localStorage.getItem(LOCALSTORAGE_KEYS.UNITS_SETTINGS);

  // If no settings are found, return default settings
  if (!settings) {
    return {
      measurementSystem: MEASUREMENT_SYSTEM.METRIC, // Default to Metric system
      customVolumeUnit: METRIC_DEFAULT_UNITS.volume, // Default volume unit for Metric
      customWeightUnit: METRIC_DEFAULT_UNITS.weight, // Default weight unit for Metric
    };
  }

  try {
    // Parse the JSON string from localStorage into an object
    return JSON.parse(settings);
  } catch (error) {
    // Log a warning if parsing fails and return default settings
    console.warn('Failed to parse unitsSettings from localStorage:', error);
    return {
      measurementSystem: MEASUREMENT_SYSTEM.METRIC,
      customVolumeUnit: METRIC_DEFAULT_UNITS.volume,
      customWeightUnit: METRIC_DEFAULT_UNITS.weight,
    };
  }
};

/**
 * Converts the given quantity to user-preferred units based on measurement system settings.
 *
 * @param {number} quantity - The quantity to be converted.
 * @param {string} units - The current unit of the quantity.
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
export const convertToUserUnits = (quantity, units) => {
  // Retrieve the user's unit settings from localStorage
  const { measurementSystem, customVolumeUnit, customWeightUnit } = getUnitsSettings();

  // Determine if the current unit is metric or imperial
  const isMetricUnit = METRIC_UNITS.includes(units);
  const isImperialUnit = IMPERIAL_UNITS.includes(units);

  // If the unit is neither metric nor imperial, log a warning and return the original quantity and unit
  if (!isMetricUnit && !isImperialUnit) {
    console.warn(`Unknown unit: ${units}`);
    return { quantity: formatTwoDecimalsNumber(quantity), units, isConverted: false };
  }

  // If the user has selected a custom measurement system
  if (measurementSystem === MEASUREMENT_SYSTEM.CUSTOM) {
    // Check if the current unit matches the user's custom units
    const isCustomWeightUnit = WEIGHT_UNITS.includes(units) && units === customWeightUnit;
    const isCustomVolumeUnit = VOLUME_UNITS.includes(units) && units === customVolumeUnit;

    if (isCustomWeightUnit || isCustomVolumeUnit) {
      // If the current unit matches the custom unit, no conversion is needed
      return { quantity: formatTwoDecimalsNumber(quantity), units, isConverted: false };
    }

    // Perform conversion to the user's custom volume or weight unit
    return performConversion(quantity, units, customVolumeUnit, customWeightUnit);
  }

  // Determine the default units based on the user's selected measurement system
  const defaultUnits =
    measurementSystem === MEASUREMENT_SYSTEM.METRIC ? METRIC_DEFAULT_UNITS : IMPERIAL_DEFAULT_UNITS;

  const targetVolumeUnit = defaultUnits.volume; // Target volume unit based on measurement system
  const targetWeightUnit = defaultUnits.weight; // Target weight unit based on measurement system

  // Check if the current unit is already in the user's selected measurement system
  const isSameSystem =
    (measurementSystem === MEASUREMENT_SYSTEM.METRIC && isMetricUnit) ||
    (measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL && isImperialUnit);

  if (isSameSystem) {
    // If the unit is already in the desired measurement system, no conversion is needed
    return { quantity: formatTwoDecimalsNumber(quantity), units, isConverted: false };
  }

  // Determine the type of unit (weight or volume) and perform the appropriate conversion
  if (WEIGHT_UNITS.includes(units)) {
    // Convert weight unit to the target weight unit
    return performConversion(quantity, units, null, targetWeightUnit);
  }

  if (VOLUME_UNITS.includes(units)) {
    // Convert volume unit to the target volume unit
    return performConversion(quantity, units, targetVolumeUnit, null);
  }

  // Fallback: if unit type is neither weight nor volume, return original quantity and unit
  return { quantity: formatTwoDecimalsNumber(quantity), units, isConverted: false };
};

/**
 * Converts the given quantity back to the original unit.
 *
 * @param {number} quantity - The quantity to be converted.
 * @param {string} currentUnit - The current unit of the quantity.
 * @param {string} originalUnit - The unit to convert back to.
 * @returns {{ quantity: number, units: string, isConverted: boolean }} The conversion result.
 */
export const performReverseConversion = (quantity, currentUnit, originalUnit) => {
  // Check if both currentUnit and originalUnit are valid and exist in the conversion map
  if (!CONVERT_UNITS_MAP[currentUnit] || !CONVERT_UNITS_MAP[originalUnit]) {
    // Log a warning if either unit is invalid and return the original quantity and unit
    console.warn(`Invalid units for reverse conversion: ${currentUnit}, ${originalUnit}`);
    return { quantity: formatTwoDecimalsNumber(quantity), units: currentUnit, isConverted: false };
  }

  /**
   * Define a post-processing function to handle specific rounding and precision needs
   * after the conversion is performed.
   *
   * @param {number} converted - The converted quantity before post-processing.
   * @returns {number} The post-processed converted quantity.
   */
  const postProcess = converted => {
    // Apply a tiny increment to address floating-point precision errors and round to two decimals
    let rounded = Math.round((converted + 1e-10) * 100) / 100;

    // Define units that should only have integer quantities
    const integerUnits = [MATERIAL_UNITS.MM3];

    // If the original unit requires integer quantities, round accordingly
    if (integerUnits.includes(originalUnit)) {
      rounded = Math.round(rounded);
    }

    return rounded;
  };

  // Perform the unit conversion with the defined post-processing function
  return convertUnits(quantity, currentUnit, originalUnit, { postProcess });
};

/**
 * Rounds a given number to the specified precision.
 *
 * @param {number} value - The number to be rounded.
 * @param {number} [precision=2] - The number of decimal places to round to. Defaults to 2 if not provided.
 * @returns {number} The rounded number with the specified precision.
 *
 * @example
 * roundToPrecision(3.14159); // Returns 3.14
 * roundToPrecision(3.14159, 3); // Returns 3.142
 * roundToPrecision(123.456, 0); // Returns 123
 */
export const roundToPrecision = (value, precision = 2) => {
  // Calculate the factor based on the desired precision (e.g., 100 for 2 decimals)
  const factor = Math.pow(10, precision);

  // Multiply, round, and divide to achieve the desired precision
  return Math.round(value * factor) / factor;
};
