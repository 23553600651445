import { useCallback, useState } from 'react';
import { performReverseConversion } from 'src/utils/conversions';
import { validateMultipleZeros } from 'src/utils/validation';

/**
 * Custom hook to manage and synchronize selected amounts between base and converted units.
 *
 * @param {number} baseBatchQuantity - The total available quantity in base units.
 * @param {string} baseUnits - The unit type of the base quantity (e.g., kg, liters).
 * @param {number} convertedBatchQuantity - The total available quantity in converted units.
 * @param {string} convertedUnits - The unit type of the converted quantity.
 * @param {number} initialSelectedAmountBase - Initial amount selected in base units (defaults to 0).
 * @param {number} initialSelectedAmountConverted - Initial amount selected in converted units (defaults to 0).
 * @returns {object} - Contains the current selected amounts and handlers to update them.
 */
const useSelectedAmountHandler = (
  baseBatchQuantity,
  baseUnits,
  convertedBatchQuantity,
  convertedUnits,
  initialSelectedAmountBase = 0,
  initialSelectedAmountConverted = 0
) => {
  // State to keep track of the currently selected amount in base units
  // initialSelectedAmountBase -> state by default (if set) when the component renders
  const [selectedAmountBase, setSelectedAmountBase] = useState(() => initialSelectedAmountBase);

  // State to keep track of the currently selected amount in converted units
  // initialSelectedAmountConverted -> state by default (if set) when the component renders
  const [selectedAmountConverted, setSelectedAmountConverted] = useState(
    () => initialSelectedAmountConverted
  );

  // Check if there is no conversion required (i.e., units are the same)
  const unitsAreSame = baseUnits === convertedUnits;

  /**
   * Handler for changes in the base amount input field.
   * This function ensures that user input is valid, clamps it within allowed limits,
   * and synchronizes the converted amount accordingly.
   *
   * @param {Event} changeEvent - The change event from the input field.
   */
  const handleSelectedAmountChange = useCallback(
    changeEvent => {
      const { value } = changeEvent.target;

      // Validate input to prevent multiple leading zeros (e.g., "0001")
      validateMultipleZeros(changeEvent);

      // If the input field is cleared, reset both amounts to zero
      if (value === '') {
        setSelectedAmountBase(0);
        setSelectedAmountConverted(0);
        return;
      }

      // Define a regex pattern to allow only numbers with up to two decimal places
      const regex = /^\d*\.?\d{0,2}$/;

      // Check if the input matches the allowed pattern
      if (regex.test(value)) {
        // Parse the input string to a floating-point number
        const numericValue = parseFloat(value);

        // Ensure the parsed value is a valid number
        if (!isNaN(numericValue)) {
          // Clamp the value to not exceed the maximum allowed converted quantity
          const clampedValue = Math.min(numericValue, convertedBatchQuantity);

          // Round the clamped value to two decimal places for consistency
          const roundedValue = Math.round(clampedValue * 100) / 100;

          // Update the converted amount state with the new rounded value
          setSelectedAmountConverted(roundedValue);

          /**
           * Check if the rounded converted amount is effectively at its maximum.
           * Using a small epsilon (1e-2) to account for floating-point precision.
           */
          if (Math.abs(roundedValue - convertedBatchQuantity) < 1e-2) {
            // If the converted amount is at its maximum, set the base amount to the full base batch quantity
            setSelectedAmountBase(baseBatchQuantity);
          } else if (unitsAreSame) {
            // Units are the same; set both amounts to roundedValue
            setSelectedAmountBase(roundedValue);
            setSelectedAmountConverted(roundedValue);
          } else {
            /**
             * Perform a reverse conversion to determine the corresponding base amount
             * based on the new converted amount.
             */
            const reverseConversion = performReverseConversion(
              roundedValue,
              convertedUnits,
              baseUnits
            );

            if (reverseConversion.isConverted) {
              // If the conversion is successful, update the base amount with the converted quantity
              setSelectedAmountBase(reverseConversion.quantity);
            } else {
              // If the conversion fails (e.g., unsupported units), default the base amount to zero
              setSelectedAmountBase(0);
            }
          }
        }
      }
      // If the input doesn't match the regex, ignore it to prevent invalid entries
    },
    [convertedBatchQuantity, convertedUnits, baseUnits, baseBatchQuantity] // Dependencies for useCallback
  );

  /**
   * Handler for changes in the base amount via a range input (e.g., slider).
   * This function ensures that the base amount stays within allowed limits
   * and updates the converted amount accordingly.
   *
   * @param {string|number} value - The new value from the range input.
   */
  const handleRangeChange = useCallback(
    value => {
      // Parse the input value to a floating-point number
      const numericValue = parseFloat(value);

      // If the parsed value is not a valid number, reset both amounts to zero
      if (isNaN(numericValue)) {
        setSelectedAmountBase(0);
        setSelectedAmountConverted(0);
        return;
      }

      // Clamp the base amount to not exceed the maximum base batch quantity
      const clampedValue = Math.min(numericValue, baseBatchQuantity);

      // Round the clamped value to two decimal places for consistency
      const roundedValue = Math.round(clampedValue * 100) / 100;

      // Update the base amount state with the new rounded value
      setSelectedAmountBase(roundedValue);

      /**
       * Check if the rounded base amount is effectively at its maximum.
       * Using a small epsilon (1e-2) to account for floating-point precision.
       */
      if (Math.abs(roundedValue - baseBatchQuantity) < 1e-2) {
        // If the base amount is at its maximum, set the converted amount to the full converted batch quantity
        setSelectedAmountConverted(convertedBatchQuantity);
      } else if (unitsAreSame) {
        // Units are the same; set both amounts to roundedValue
        setSelectedAmountBase(roundedValue);
        setSelectedAmountConverted(roundedValue);
      } else {
        /**
         * Perform a conversion to determine the corresponding converted amount
         * based on the new base amount.
         */
        const conversionResult = performReverseConversion(roundedValue, baseUnits, convertedUnits);

        if (conversionResult.isConverted) {
          // If the conversion is successful, update the converted amount with the converted quantity
          setSelectedAmountConverted(conversionResult.quantity);
        } else {
          // If the conversion fails (e.g., unsupported units), default the converted amount to zero
          setSelectedAmountConverted(0);
        }
      }
    },
    [baseBatchQuantity, baseUnits, convertedUnits, convertedBatchQuantity] // Dependencies for useCallback
  );

  // Return the current selected amounts and the handlers to update them
  return {
    selectedAmountBase,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
  };
};

export default useSelectedAmountHandler;
