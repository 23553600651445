import { validate as validEmail } from 'email-validator';
import { performReverseConversion } from 'src/utils/conversions';

export const isValidEmail = email => {
  const trimmedEmail = `${email}`.trim();

  if (!trimmedEmail) {
    throw new TypeError('Please enter your email address.');
  }

  if (!validEmail(trimmedEmail)) {
    throw new TypeError('Invalid email address.');
  }

  return true;
};

// This is a simple required validator for now. If we add a password reset or
// signup form, we should expand to check for more things, but keep it the same
// for logging in. - Brad
export const isValidPassword = password => {
  if (!password) {
    throw new TypeError('Please enter your password.');
  }

  return true;
};

export const isValidDate = date => {
  const parsed = Date.parse(date);
  return isNaN(date) && !isNaN(parsed);
};

export const validateMultipleZeros = event => {
  const inputValue = event.target.value;

  if (/^0{2,}$/.test(inputValue)) {
    // If the input is multiple zeros, reset to a single zero
    event.target.value = '0';
  } else if (/^0[0-9]+/.test(inputValue)) {
    // If the input starts with zero followed by other digits, strip leading zeros
    event.target.value = inputValue.replace(/^0+/, '');
  }
};

/**
 * Determines the step value for a slider based on the quantity provided.
 *
 * @param {number} quantity - The quantity to determine the slider step for.
 * @returns {number} - The step value for the slider.
 */
export const getSliderStep = quantity => {
  if (!quantity) return 1;
  // If the value is less than 1, return 0.01 as the step
  if (quantity < 1) {
    return 0.01;
  }

  // Determine the number of decimal places in the quantity
  const decimalPlaces = quantity.toString().split('.')[1]?.length || 0;

  // If there are no decimal places, return 1
  if (decimalPlaces === 0) {
    return 1;
  }

  // Calculate step size based on the number of decimal places
  const stepSize = Math.pow(10, -decimalPlaces);

  return stepSize;
};

/**
 * Handles changes to the converted quantity input field.
 *
 * @param {object} params - The parameters for handling the change.
 * @param {Event} params.e - The event triggered by the input change.
 * @param {function} params.setBaseQuantity - Function to update the base quantity state.
 * @param {function} params.setConvertedQuantity - Function to update the converted quantity state.
 * @param {number} params.batchQuantity - The total available quantity in base units.
 * @param {string} params.batchUnits - The unit type of the base quantity.
 * @param {number} params.convertedBatchQuantity - The total available quantity in converted units.
 * @param {string} params.convertedBatchUnits - The unit type of the converted quantity.
 * @param {function} params.validateMultipleZeros - Function to validate and prevent multiple leading zeros.
 */
export const handleConvertedQuantityChange = ({
  e,
  setBaseQuantity,
  setConvertedQuantity,
  batchQuantity,
  batchUnits,
  convertedBatchQuantity,
  convertedBatchUnits,
  validateMultipleZeros,
}) => {
  // Extract the input value from the event target
  const value = e.target.value;

  // Validate the input to prevent multiple leading zeros
  validateMultipleZeros(e);

  // If the input is empty, reset both base and converted quantities
  if (value === '') {
    setBaseQuantity(0);
    setConvertedQuantity('');
    return;
  }

  // Define a regex pattern to allow only numbers with up to two decimal places
  const regex = /^\d*\.?\d{0,2}$/;

  // Check if the input value matches the allowed pattern
  if (regex.test(value)) {
    // Parse the input string to a floating-point number
    const numericValue = parseFloat(value);

    // Ensure the parsed value is a valid number
    if (!isNaN(numericValue)) {
      // Clamp the numeric value to not exceed the maximum converted batch quantity
      const clampedValue = Math.min(numericValue, convertedBatchQuantity);

      // Round the clamped value to two decimal places for consistency
      const roundedValue = Math.round(clampedValue * 100) / 100;

      // Update the converted quantity state with the rounded value
      setConvertedQuantity(roundedValue);

      // Check if there is no conversion required (i.e., units are the same)
      const unitsAreSame = batchUnits === convertedBatchUnits;

      /**
       * Check if the rounded converted quantity is effectively at its maximum.
       * Using a small epsilon (1e-2) to account for floating-point precision.
       */
      if (Math.abs(roundedValue - convertedBatchQuantity) < 1e-2) {
        // If the converted quantity is at its maximum, set the base quantity to the full batch quantity
        setBaseQuantity(batchQuantity);
      } else if (unitsAreSame) {
        // Units are the same; set both amounts to roundedValue
        setBaseQuantity(roundedValue);
        setConvertedQuantity(roundedValue);
      } else {
        /**
         * Perform a reverse conversion to determine the corresponding base quantity
         * based on the new converted quantity.
         */
        const reverseConversion = performReverseConversion(
          roundedValue,
          convertedBatchUnits,
          batchUnits
        );

        if (reverseConversion.isConverted) {
          // If the reverse conversion is successful, update the base quantity with the converted value
          setBaseQuantity(reverseConversion.quantity);
        } else {
          // If the reverse conversion fails (e.g., unsupported units), default the base quantity to zero
          setBaseQuantity(0);
        }
      }
    }
  }
  // If the input doesn't match the regex, ignore it to prevent invalid entries
};

/**
 * Formats a percentage value dynamically based on whether the denominator has decimals.
 * - If the denominator has decimals, the percentage is formatted with one decimal place.
 * - If the denominator does not have decimals, the percentage is formatted as an integer.
 *
 * @param {number} numerator - The numerator for calculating the percentage.
 * @param {number} denominator - The denominator for calculating the percentage.
 * @returns {number} - The formatted percentage (e.g., "7", "6.7", or "0").
 */
export const formatDynamicPercentage = (numerator, denominator) => {
  // Handle invalid inputs or division by zero
  if (!numerator || !denominator || denominator <= 0) {
    return 0;
  }

  // Calculate the raw percentage
  const percentage = (numerator / denominator) * 100;

  // Detect if the numerator has decimals
  const numeratorHasDecimals = !Number.isInteger(numerator);

  // Format percentage based on the presence of decimals in the denominator
  return numeratorHasDecimals
    ? percentage.toFixed(1) // Decimal formatting
    : Math.round(percentage); // Integer formatting
};
