import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import logoImg from 'public/static/icon-192';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BarcodeQrCodeOutput from 'src/components/BarcodeQrCodeOutput';
import IntlDate from 'src/components/intl-date';
import Loader from 'src/components/loader';
import { api, fetchDataByUris } from 'src/utils/api';
import config from 'src/utils/config';
import {
  API_RESOURCES,
  BUREAU_BARCODE_FORMAT,
  MATERIAL_CONTAINER_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import useQueryParams, { getQRAppUri, getRouteURI, getShortUuid, getUuid } from 'src/utils/url';


const ContainersQRCodeTraveler = () => {
  const [printer, setPrinter] = useState(null);
  const [printerType, setPrinterType] = useState(null);
  const [printerLocation, setPrinterLocation] = useState(null);
  const [scanningMode, setScanningMode] = useState(BUREAU_BARCODE_FORMAT.QR);
  const [containers, setContainers] = useState([]);
  const [lots, setLots] = useState({});
  const [batchMaterialLotUUID, setBatchMaterialLotUUID] = useState(null);
  const [materials, setMaterials] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    forResource,
    backUri,
    allContainers,
    disposableContainers,
  } = useQueryParams();

  const navigate = useNavigate();

  // Redirect to the initial URL or to the Scan page
  const handleRedirectBack = () => navigate(backUri || getRouteURI(routes.scan), { replace: true });

  const redirectToFlowsPrinterType = (printerTypeUri) => {
    if (!printerTypeUri) return null;
    return window.open(`${config.rapidfabHost}/#/assets/printer-types/?uuid=${getUuid(printerTypeUri)}`, '_blank');
  };

  // Material Container can be Permanent Container as well
  const availableResources = [API_RESOURCES.MATERIAL_CONTAINER, API_RESOURCES.MATERIAL_BATCH, API_RESOURCES.PRINTER];

  const identifyResourceToFetch = () => {
    if (!forResource) return null;
    // Identify which original resource we need to fetch the information for
    return availableResources.find(resource => forResource.includes(resource));
  };

  const fetchNonEmptyContainers = async (containers) => {
    const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

    const additionalParams = {
      'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
      'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
    };

    const containersByUri = await fetchDataByUris(
      API_RESOURCES.MATERIAL_CONTAINER,
      containers,
      additionalParams
    );

    if (containersByUri) {
      const nonEmptyContainers = Object.values(containersByUri);
      setContainers(nonEmptyContainers);
      return nonEmptyContainers;
    }

    return null;
  };


  const fetchMaterialLotAndMaterialsData = async (containers) => {
    try {
      const containerLotsUris = containers.map(container => container.material_lot).filter(Boolean);
      const containerMaterialsUris = containers.map(container => container.material).filter(Boolean);

      const [materialLotsByUri, materialsByUri] = await Promise.all([
        fetchDataByUris(API_RESOURCES.MATERIAL_LOT, containerLotsUris),
        fetchDataByUris(API_RESOURCES.MATERIAL, containerMaterialsUris),
      ]);

      if (materialLotsByUri) {
        setLots(materialLotsByUri);
      }

      if (materialsByUri) {
        setMaterials(materialsByUri);
      }
    } catch (error) {
      console.error(error);
      setError(error.message || String(error));
    }
  };


  const fetchMaterialBatchData = async () => {
    try {
      const batchData = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(forResource)}/`).json();
      const batchContainersUris = batchData?.containers;

      if (!_isEmpty(batchContainersUris)) {
        const fetchedContainers = await fetchNonEmptyContainers(batchContainersUris);
        await fetchMaterialLotAndMaterialsData(fetchedContainers);

        return fetchedContainers;
      }
    } catch (error) {
      console.error(error);
      setError(error.message || String(error));
    }

  };

  const fetchPrinterData = async () => {
    try {
      const printer = await api.get(`${API_RESOURCES.PRINTER}/${getUuid(forResource)}/`).json();
      const printerType = printer?.printer_type && await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(printer.printer_type)}/`).json();
      const printerMaterialsUris = printerType?.materials;
      const printerLocation = printer?.location && await api.get(`${API_RESOURCES.LOCATION}/${getUuid(printer.location)}/`).json();

      if (!_isEmpty(printerMaterialsUris)) {
        const materialsByUri = await fetchDataByUris(API_RESOURCES.MATERIAL, printerMaterialsUris);

        if (materialsByUri) {
          setMaterials(materialsByUri);
        }
      }

      setPrinter(printer);
      setPrinterType(printerType);
      setPrinterLocation(printerLocation);
    } catch (error) {
      console.error(error);
      setError(error.message || String(error));
    }
  };

  const fetchDisposableContainers = async () => {
    // As we pass the "UUIDs", we need to transform to the actual URIs
    const transformedDisposableContainerUris = disposableContainers.split(',').map(containerUUID =>
      `${config.apiHost}/${API_RESOURCES.MATERIAL_CONTAINER}/${containerUUID}/`
    );
    try {
      const { resources: containers } = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
        searchParams: {
          'filter[uri]': transformedDisposableContainerUris.join(','),
        },
      }).json();

      if (!_isEmpty(containers)) {
        setContainers(containers);
        await fetchMaterialLotAndMaterialsData(containers);
      }
    } catch (error) {
      console.error(error);
      setError(error.message || String(error));
    }

  };

  const fetchMaterialContainerData = async () => {
    if (!_isEmpty(disposableContainers) && allContainers) {
      return await fetchDisposableContainers();
    }

    try {
      const container = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${getUuid(forResource)}/`).json();

      if (!_isEmpty(container)) {
        // If the container does not have a material lot, fetch the Current Batch (if available)
        // And set the first Material Lot from the Batch
        if (!container.material_lot && container.current_batch) {
          const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(container.current_batch)}/`).json();
          const batchMaterialLots = batch?.material_lots;

          // If the Batch has Material Lots, set the Short UUID of the first instead
          if (!_isEmpty(batchMaterialLots)) {
            setBatchMaterialLotUUID(getShortUuid(batchMaterialLots[0]));
          }
        }
        setContainers([container]);
        await fetchMaterialLotAndMaterialsData([container]);
      }
    } catch (error) {
      console.error(error);
      setError(error.message || String(error));
    }

  };

  const fetchResourceData = async () => {
    const resourceToFetch = identifyResourceToFetch();

    if (!resourceToFetch) return;

    switch (resourceToFetch) {
      case API_RESOURCES.MATERIAL_CONTAINER:
        await fetchMaterialContainerData();
        break;
      case API_RESOURCES.MATERIAL_BATCH:
        await fetchMaterialBatchData();
        break;
      case API_RESOURCES.PRINTER:
        await fetchPrinterData();
        break;
      default:
        break;
    }
  };

  const getBureauSettings = async () => {
    try {
      const bureauSettingsResponse = await api.get(`${API_RESOURCES.BUREAU_SETTINGS}`).json();
      const bureauBarcodeFormatSettings = bureauSettingsResponse?.resources?.[0].barcode_default_format;
      if (bureauBarcodeFormatSettings) {
        setScanningMode(bureauBarcodeFormatSettings);
      }
    } catch (error) {
      console.error(error);
      setError(error.message || String(error));
    }
  };

  const onInitialize = async () => {
    setLoading(true);
    await getBureauSettings();
    await fetchResourceData();
    setLoading(false);
  };


  useEffect(() => {
    onInitialize();
  }, []);


  const renderContainerInfo = (container) => (
    <div key={container.uri} className="traveler-secondary">
      <BarcodeQrCodeOutput
        url={getQRAppUri(container.uri)} scanningMode={scanningMode}
        className="qr-code-initial hide-on-print" />

      <div className="print-general-container">
        <div className="print-info print-info-general">

          <div className="print-block">
            <div className="print-title">Material Container:{' '}
              <span className="print-description">
                {container.disposable
                  ? getShortUuid(container.uri)
                  : `${container.name} (${getShortUuid(container.uri)})`}
              </span>
            </div>
          </div>


          <div className="print-block">
            <div className="print-title">Material Type:{' '}
              <span className="print-description">{materials[container.material]?.name || 'N/A'}</span>
            </div>
          </div>


          <BarcodeQrCodeOutput
            url={getQRAppUri(container.uri)} scanningMode={scanningMode}
            className="qr-code-printable only-printable" />

          <div className="print-block">
            <div className="print-title">Material Lot:{' '}
              <span
                className="print-description"
              >{getShortUuid(lots[container.material_lot]?.uri) || batchMaterialLotUUID || 'N/A'}
              </span>
            </div>
          </div>

          <div className="print-block">
            <div className="mt15 print-title">Created:{' '}
              <span className="mt15 print-description">{<IntlDate
                withTime date={container.created}
                locale={window.navigator.languages[0]} /> || 'N/A'}
              </span>
            </div>
          </div>

        </div>

        <footer className="print-general-footer">
          <p>
            Powered by <img className="logo" src={logoImg} alt="Powered by Authentise" /> Authentise
          </p>
        </footer>
      </div>

      <footer className="print-general-footer-mobile">
        <p>
          Powered by <img className="logo" src={logoImg} alt="Powered by Authentise" /> Authentise
        </p>
      </footer>
    </div>
  );

  const renderPrinterInfo = (printer) => {
    const materialValues = Object.values(materials);

    return (
      <div key={printer.uri} className="traveler-secondary">
        <BarcodeQrCodeOutput
          url={getQRAppUri(printer.uri)} scanningMode={scanningMode}
          className="qr-code-initial hide-on-print" />

        <div className="print-general-container">
          <div className="print-info print-info-general">

            <div className="print-block">
              <div className="print-title">Printer Type:{' '}
                <span className="print-description">
                  {printerType?.name || 'N/A'}
                </span>
              </div>
            </div>


            <div className="print-block">
              <div className="print-title">Printer Name:{' '}
                <span className="print-description">{printer?.name || 'N/A'}</span>
              </div>
            </div>


            <BarcodeQrCodeOutput
              url={getQRAppUri(printer.uri)} scanningMode={scanningMode}
              className="qr-code-printable only-printable" />

            <div className="print-block">
              <div className="print-title">Location:{' '}
                <span className="print-description">{printerLocation?.name || 'N/A'}</span>
              </div>
            </div>

            <div className="print-block">
              <div className="print-title">Material Types:{' '}
                <span className="print-description">
                  {/* For a full list of material types please navigate to the printer type page in Flows */}
                  {!_isEmpty(materialValues) ? (
                    <>
                      <div className="non-printable printerMaterialsInfoTraveler">
                        {materialValues.slice(0, 4).map(material => material.name).join(', ')}
                        {materialValues.length > 5 && (
                          <li>
                            Plus {materialValues.length - 4} more.{' '}
                            <Button
                              variant="link" className="p-a-0"
                              onClick={() => redirectToFlowsPrinterType(printerType?.uri)}
                            >
                              View all here
                            </Button>
                          </li>
                        )}
                      </div>
                      <div className="only-printable">
                        {materialValues.length}
                      </div>
                    </>
                  ) : (
                    'N/A'
                  )}
                </span>
              </div>
              <div className="only-printable">For a full list, please navigate to the printer
                type page in Flows
              </div>
            </div>

          </div>

          <footer className="print-general-footer">
            <p>
              Powered by <img className="logo" src={logoImg} alt="Powered by Authentise" /> Authentise
            </p>
          </footer>
        </div>

        <footer className="print-general-footer-mobile">
          <p>
            Powered by <img className="logo" src={logoImg} alt="Powered by Authentise" /> Authentise
          </p>
        </footer>
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {String(error)}</div>;
  }

  if (_isEmpty(containers) && !printer) {
    return <div>No containers found</div>;
  }

  return (
    <div>
      <div className="floating-button-container hide-on-print">
        <div className="floating-button floating-button-skip-animation" onClick={handleRedirectBack}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </div>
      </div>

      {printer ? renderPrinterInfo(printer) : containers.map(container => renderContainerInfo(container))}
    </div>
  );


};

export default ContainersQRCodeTraveler;
