import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Piece from 'src/pages/piece/[uuid]';
import PieceOverview from 'src/pages/piece-overview/[uuid]';
import Shipment from 'src/pages/shipment/[uuid]';
import SummaryPage from 'src/pages/summary';
import PrintTravelerPage from 'src/pages/traveler/print/[print]';
import ContainersQRCodeTraveler from 'src/pages/traveler/qr-codes/ContainersQRCodeTraveler';
import RunTravelerPage from 'src/pages/traveler/run/[run]';
import PermanentContainerRoutes from 'src/routes/PermanentContainerRoutes';
import PrinterRoutes from 'src/routes/PrinterRoutes';
import PrivateRoute from 'src/routes/PrivateRoute';
import PublicRoute from 'src/routes/PublicRoute';
import RunRoutes from 'src/routes/RunRoutes';

import AboutPage from '../pages/about';
import ActivityPage from '../pages/activity';
import HomePage from '../pages/home';
import LoginPage from '../pages/log-in';
import LogoutPage from '../pages/log-out';
import LogonScan from '../pages/logon-scan';
import NotFoundPage from '../pages/not-found';
import ScanPage from '../pages/scan';
import routes from '../utils/routes';
import MaterialBatchRoutes from './MaterialBatchRoutes';
import MaterialContainerRoutes from './MaterialContainerRoutes';
import ToolRoutes from './ToolRoutes';

const AppRoutes = ({ user }) => (
  <Routes>
    <Route path={routes.login} element={<PublicRoute Component={LoginPage} />} />
    <Route path={routes.logout} element={<PrivateRoute Component={LogoutPage} />} />
    <Route path={routes.logonScan} element={<PublicRoute Component={LogonScan} />} />

    <Route path={routes.home} element={<PrivateRoute Component={HomePage} />} />
    <Route path={routes.about} element={<PrivateRoute Component={AboutPage} />} />
    <Route path={routes.scan} element={<PrivateRoute Component={ScanPage} />} />
    <Route path={routes.activity} element={<PrivateRoute Component={ActivityPage} />} />

    <Route path='/' element={user ? <Outlet /> : <Navigate to={routes.login} />}>
      <Route path={`${routes.materialContainer}/*`} element={<MaterialContainerRoutes />} />
      <Route path={`${routes.materialBatch}/*`} element={<MaterialBatchRoutes />} />
      <Route path={`${routes.permanentContainer}/*`} element={<PermanentContainerRoutes />} />
      <Route path={`${routes.printer}/*`} element={<PrinterRoutes />} />
      <Route path={`${routes.runRoute}/*`} element={<RunRoutes />} />
      <Route path={`${routes.toolRoute}/*`} element={<ToolRoutes />} />
      <Route path={routes.travelerPrint} element={<PrivateRoute Component={PrintTravelerPage} />} />
      <Route path={routes.travelerRun} element={<PrivateRoute Component={RunTravelerPage} />} />
      <Route
        path={routes.travelerContainers}
        element={<PrivateRoute Component={ContainersQRCodeTraveler} />}
      />
      <Route path={routes.summary} element={<PrivateRoute Component={SummaryPage} />} />
      <Route path={routes.piece} element={<PrivateRoute Component={Piece} />} />
      <Route path={routes.pieceOverview} element={<PrivateRoute Component={PieceOverview} />} />
      <Route path={routes.shipment} element={<PrivateRoute Component={Shipment} />} />
    </Route>
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

AppRoutes.propTypes = {
  user: PropTypes.shape({
    initialLogin: PropTypes.bool,
  }).isRequired,
};

export default AppRoutes;
