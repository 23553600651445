import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import TruncatedTitle from 'src/components/TruncatedTitle';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';

const ContainerQRCodes = ({
  printer,
  containers,
  backUri,
  forResource,
  forDisposableContainers,
}) => {
  const { closeActionPanel } = useActionPanelStore();

  if (_isEmpty(containers) && !printer) {
    return <div>No containers found or all are empty.</div>;
  }

  const renderQrCode = (resource, type) => {
    if (!resource || !type) return null;

    const showUuid = type === 'container' && resource?.disposable;

    return (
      <div key={resource.uri} className='container-qr-code'>
        <Link
          to={getRouteURI(
            routes.travelerContainers,
            {},
            {
              backUri,
              forResource: resource.uri,
            }
          )}
          onClick={closeActionPanel}
        >
          <FontAwesomeIcon icon={faQrcode} className='container-qr-code-icon' />
          {showUuid ? (
            <p>{getShortUuid(resource.uuid)}</p>
          ) : (
            <TruncatedTitle title={resource.name} maxTextLength={25} maxTextLengthMobile={20} />
          )}
        </Link>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'container-qr-codes',
        containers?.length > 2 ? 'justify-content-between' : 'gap30'
      )}
    >
      {containers.length > 1 && (
        <div className='all-containers-active'>
          <Link
            to={getRouteURI(
              routes.travelerContainers,
              {},
              {
                backUri,
                forResource,
                allContainers: true,
                disposableContainers:
                  forDisposableContainers && containers.map(container => getUuid(container.uri)),
              }
            )}
            onClick={closeActionPanel}
          >
            <FontAwesomeIcon
              icon={faQrcode}
              className='container-qr-code-icon all-containers-active-icon'
            />
            <p>All Containers</p>
          </Link>
        </div>
      )}
      {printer
        ? renderQrCode(printer, 'printer')
        : containers.map(container => renderQrCode(container, 'container'))}
    </div>
  );
};

ContainerQRCodes.defaultProps = {
  containers: [],
  printer: null,
  forDisposableContainers: false,
};

ContainerQRCodes.propTypes = {
  containers: PropTypes.arrayOf(PropTypes.shape({})),
  backUri: PropTypes.string.isRequired,
  forResource: PropTypes.string.isRequired,
  printer: PropTypes.shape({}),
  forDisposableContainers: PropTypes.bool,
};

export default ContainerQRCodes;
