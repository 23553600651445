import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ActionSpinner from 'src/components/loaders/action-spinner';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { apiWithContext } from 'src/utils/api';
import {
  API_RESOURCES,
  TOOLING_STOCK_ACTION_TYPES,
} from 'src/utils/constants';

const ToolActionForm = ({ tool, action, closePanel, handleSubmit, setError }) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [subLocationOptions, setSubLocationOptions] = useState([]);
  const [loadingSubLocations, setLoadingSubLocations] = useState(false);

  const [loading, setLoading] = useState(false);

  const { setActionPanelProps } = useActionPanelStore();

  const locationSelectRequired = (action) => ([TOOLING_STOCK_ACTION_TYPES.RELOCATE, TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE].includes(action));
  const notesRequired = (action) => ([TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS, TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH].includes(action));

  const onLocationChange = async (location) => {
    setLoadingSubLocations(true);
    if (!location) {
      setSubLocationOptions([]);
    }
    const api = apiWithContext();
    const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
      searchParams: {
        'filter[location]': location,
      },
    }).json();
    setSubLocationOptions(subLocations);
    setLoadingSubLocations(false);
  };

  const fetchLocations = async () => {
    if (locationSelectRequired(action)) {
      setLoading(true);
      const api = apiWithContext();
      try {
        const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`, {}).json();
        setLocationOptions(locations);
      } catch (error) {
        console.error(error);
        setActionPanelProps({ errorMessage: error.message });
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  if (loading || !tool) {
    return (
      <ActionSpinner />
    );
  }

  return (
    <Formik
      initialValues={{
        location: '',
        subLocation: '',
      }}
      onSubmit={(form) => handleSubmit(form, action)}
    >
      {({ isSubmitting, setFieldValue, handleChange }) => {
        return (
          <Form>
            {locationSelectRequired(action) && (
              <>
                <label className="text-left d-block" htmlFor="location">Location: *</label>
                <Field
                  required
                  component="select"
                  id="location"
                  name="location"
                  className="form-control mb-4"
                  onChange={(e) => {
                    handleChange(e);
                    void onLocationChange(e.target.value);
                    setFieldValue('subLocation', '');
                  }}
                >
                  <option value="">Choose...</option>
                  {locationOptions.map((location) => (
                    <option key={location?.uri} value={location?.uri}>{location?.name}</option>
                  ))}
                </Field>
                <label className="text-left d-block" htmlFor="subLocation">Sub-Location:</label>
                <Field
                  component="select"
                  className="form-control mb-4"
                  id="subLocation"
                  name="subLocation"
                  disabled={loadingSubLocations}
                >
                  <option value="">Choose...</option>
                  {subLocationOptions.map((subLocation) => (
                    <option key={subLocation?.uri} value={subLocation?.uri}>{subLocation?.name}</option>
                  ))}
                </Field>
              </>
            )}

            <label className="text-left d-block" htmlFor="notes">Notes:{notesRequired(action) && ' *'}</label>
            <Field
              required={notesRequired(action)}
              className="form-control mb-4"
              id="notes"
              name="notes"
              as="textarea"
            />
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-block"
              disabled={isSubmitting}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-lg btn-link btn-block"
              disabled={isSubmitting}
              onClick={closePanel}
            >
              Cancel
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};

ToolActionForm.propTypes = {
  tool: PropTypes.shape({
    location: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    sub_location: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  action: PropTypes.string.isRequired,
  closePanel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default ToolActionForm;
