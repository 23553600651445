import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React from 'react';
import Barcode from 'react-barcode';
import { encodedBarcodeSummaryUrl } from 'src/utils/barcodeEncoding';
import { BUREAU_BARCODE_FORMAT } from 'src/utils/constants';

const renderCodeOutput = (url, isBarcode) => {
  if (!url) {
    return <svg width='250' height='250' />;
  }

  return (
    <a href={url}>
      {isBarcode ? (
        <Barcode width={1.5} value={encodedBarcodeSummaryUrl(url)} />
      ) : (
        <QRCode size={250} value={url} renderAs='svg' />
      )}
    </a>
  );
};

const BarcodeQrCodeOutput = ({ url, scanningMode, className }) => {
  const isBarcode = scanningMode === BUREAU_BARCODE_FORMAT.BARCODE;

  return (
    <div
      className={`${className || ''} ${isBarcode ? BUREAU_BARCODE_FORMAT.BARCODE : BUREAU_BARCODE_FORMAT.QR}`}
    >
      {renderCodeOutput(url, isBarcode)}
    </div>
  );
};

BarcodeQrCodeOutput.defaultProps = {
  className: '',
};

BarcodeQrCodeOutput.propTypes = {
  url: PropTypes.string.isRequired,
  scanningMode: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BarcodeQrCodeOutput;
