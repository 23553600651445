import {
  faArrowsSpin,
  faArrowUpShortWide,
  faLocationDot,
  faObjectGroup,
} from '@fortawesome/free-solid-svg-icons';
import { faCoins, faCubes, faExclamationCircle, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConvertedUnits from 'src/components/ConvertedUnits';
import StatusColorCode from 'src/components/status-color-dot';
import TruncatedTitle from 'src/components/TruncatedTitle';
import {
  MATERIAL_BATCH_STATUS_VERBOSE,
  PERMANENT_CONTAINER_STATUS_VERBOSE,
  STATUS_COLOR_CODE_TYPES,
} from 'src/utils/constants';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';

const MaterialBatchSuccessCardDetails = ({
  batch,
  title = 'Batch',
  customLocation,
  subLocation,
  batchUUID,
  printer,
  isError,
  permanentContainer,
  isDisposableContainer,
  customQuantity,
  customMainStyle,
  swapRightIconPosition,
}) => {
  const [locationShown, setLocationShown] = React.useState(false);

  if (!batch) return null;

  if (isError && permanentContainer) {
    return (
      <p className='error-loading-card-resource'>
        <FontAwesomeIcon icon={faExclamationCircle} className='d-block spacer-right danger-color' />
        An error occurred while loading the Details.
      </p>
    );
  }

  const materials = permanentContainer
    ? []
    : batch.materials.map(material => material.name).join(', ') || 'N/A';

  const renderItemWithTooltipByCondition = (text, icon, shouldShowTooltip) =>
    !permanentContainer ? (
      <p className='mb0 truncated-materials-success-page'>
        <FontAwesomeIcon className='spacer-right' icon={icon} />
        {shouldShowTooltip ? (
          <OverlayTrigger placement='top' overlay={<Tooltip>{text}</Tooltip>}>
            <span>{text}</span>
          </OverlayTrigger>
        ) : (
          <span>{text}</span>
        )}
      </p>
    ) : null;

  const toggleLocation = () => setLocationShown(previous => !previous);

  const renderTitleIcon = () => {
    if (printer) return <FontAwesomeIcon className='spacer-right' icon={faPrint} />;
    if (permanentContainer) return <FontAwesomeIcon className='spacer-right' icon={faCoins} />;
    return null;
  };

  const renderCardTitle = () => {
    if (printer) {
      return (
        <Link to={getRouteURI(routes.printerMaterial, { uuid: getUuid(printer.uri) })}>
          <span className='material-batch-card-details-header-batch font-medium mb0'>
            {printer.name}
          </span>
        </Link>
      );
    }

    if (permanentContainer) {
      if (isDisposableContainer) {
        return 'Disposable Container';
      }

      return (
        <Link
          to={getRouteURI(routes.permanentContainerDetails, {
            uuid: getUuid(permanentContainer.uri),
          })}
        >
          <TruncatedTitle
            inline
            title={permanentContainer.name}
            titleClassName='material-batch-card-details-header-batch font-medium mb0'
            maxTextLength={25}
            maxTextLengthMobile={20}
          />
        </Link>
      );
    }

    return title;
  };

  const quantityIcon = (
    <FontAwesomeIcon
      className={swapRightIconPosition ? 'spacer-left' : 'spacer-right'}
      icon={faArrowUpShortWide}
    />
  );

  return (
    <div className='material-batch-card-details' style={customMainStyle}>
      <div className='material-batch-card-details-header'>
        <div className='d-flex align-items-center'>
          {renderTitleIcon()}
          <p>{renderCardTitle()}</p>
        </div>
        {!printer && !permanentContainer && (
          <Link to={getRouteURI(routes.materialContainer, {}, { batch: batchUUID || batch.uuid })}>
            <p className='material-batch-card-details-header-batch mb0'>
              {getShortUuid(batchUUID || batch.uuid)}
            </p>
          </Link>
        )}
      </div>
      <div className='material-batch-card-details-body'>
        <div className='d-flex align-items-center justify-content-between mb8'>
          <div className='d-flex align-items-center'>
            {printer ? (
              <>
                <FontAwesomeIcon
                  className='spacer-right material-batch-card-details-icon-size'
                  icon={faCubes}
                />
                <Link
                  to={getRouteURI(routes.materialContainer, {}, { batch: batchUUID || batch.uuid })}
                >
                  <p className='material-batch-card-details-header-batch mb0'>
                    {getShortUuid(batchUUID || batch.uuid)}
                  </p>
                </Link>
              </>
            ) : (
              <>
                <p className='mb0 d-flex align-items-center'>
                  <StatusColorCode
                    status={permanentContainer?.status || batch?.status}
                    type={
                      permanentContainer
                        ? STATUS_COLOR_CODE_TYPES.PERMANENT_CONTAINER_STATUS
                        : STATUS_COLOR_CODE_TYPES.BATCH_STATUS
                    }
                    sizeClassName='small'
                  />
                </p>
                <p className='mb0 spacer-left'>
                  {permanentContainer
                    ? PERMANENT_CONTAINER_STATUS_VERBOSE[permanentContainer?.status]
                    : MATERIAL_BATCH_STATUS_VERBOSE[batch?.status]}
                </p>
              </>
            )}
          </div>
          <p className='mb0'>
            {!swapRightIconPosition && quantityIcon}
            <ConvertedUnits quantity={customQuantity || batch.quantity} units={batch.units} />
            {swapRightIconPosition && quantityIcon}
          </p>
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          {renderItemWithTooltipByCondition(materials, faObjectGroup, materials.length > 30)}
          {!permanentContainer && (
            <p className='mb0'>
              <FontAwesomeIcon className='spacer-right' icon={faArrowsSpin} />
              {batch.usage_cycles}
            </p>
          )}
        </div>
        <div className='material-batch-card-details-location'>
          <p className='mb0 badge badge-secondary' onClick={toggleLocation}>
            {!locationShown ? 'Show' : 'Hide'} Location
          </p>
        </div>
        {locationShown ? (
          <div
            style={{
              textAlign: 'left',
              marginTop: '10px',
            }}
          >
            <div>
              <div className='d-flex align-items-center'>
                <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
                <p className='mb0'>{customLocation || batch.location_name}</p>
              </div>
              <p className='mb0 badge' style={{ paddingLeft: 0, marginLeft: '19px' }}>
                {subLocation}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

MaterialBatchSuccessCardDetails.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    units: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    // eslint-disable-next-line camelcase
    location_name: PropTypes.string,
    // eslint-disable-next-line camelcase
    powder_quality: PropTypes.string,
    // eslint-disable-next-line camelcase
    usage_cycles: PropTypes.number,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }),
  permanentContainer: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    uri: PropTypes.string,
  }),
  printer: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
  }),
  title: PropTypes.string,
  customLocation: PropTypes.string,
  subLocation: PropTypes.string,
  batchUUID: PropTypes.string,
  customQuantity: PropTypes.number,
  customMainStyle: PropTypes.shape({}),
  isDisposableContainer: PropTypes.bool,
  swapRightIconPosition: PropTypes.bool,
  isError: PropTypes.bool,
};

MaterialBatchSuccessCardDetails.defaultProps = {
  batch: null,
  permanentContainer: null,
  printer: null,
  title: 'Batch',
  customLocation: null,
  subLocation: null,
  batchUUID: null,
  customQuantity: null,
  customMainStyle: {},
  isDisposableContainer: false,
  swapRightIconPosition: false,
  isError: false,
};

export default MaterialBatchSuccessCardDetails;
