import PropTypes from 'prop-types';
import React from 'react';

const UnitSelector = ({ label, units, value, disabled, onChange }) => (
  <div className='unit-group'>
    <dt>{label}:</dt>
    <dd>
      <select
        disabled={disabled}
        value={value}
        className='form-control'
        onChange={e => onChange(e.target.value)}
      >
        {units.map(unit => (
          <option key={unit} value={unit}>
            {unit}
          </option>
        ))}
      </select>
    </dd>
  </div>
);

UnitSelector.propTypes = {
  label: PropTypes.string.isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default UnitSelector;
