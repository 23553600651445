import {
  faArchive,
  faCog,
  faObjectGroup, faRecycle,
  faSortAmountUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import { getShortUuid } from 'src/utils/url';

const MaterialChips = ({ material, materialBatch, materialLot, isMaterialLoading }) => {

  if (isMaterialLoading) {
    return <Loader inline text="Loading matherial chips" />;
  }

  if (!material) {
    return null;
  }

  return (
    <div className="material-chips-container mb-2">
      <span className="material-chips-label left">
        Material:
      </span>
      <div className="material-chips-content">
        {materialBatch &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">Batch ID</Tooltip>}
          >
            <p className="labelStyle"><FontAwesomeIcon
              icon={faCog}
              className="mr-1" />{getShortUuid(materialBatch?.uuid)}
            </p>
          </OverlayTrigger>}

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">Material Type and Color</Tooltip>}
        >
          <p className="labelStyle"><FontAwesomeIcon icon={faObjectGroup} className="mr-1" /> {material.name} <span
            className="labelStyleColor" style={{ background: material.color }} />
          </p>
        </OverlayTrigger>

        {materialBatch &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">Amount Loaded</Tooltip>}
          >
            <p className="labelStyle">
              <FontAwesomeIcon icon={faSortAmountUp} className="mr-1" />&nbsp;
              <ConvertedUnits
                quantity={materialBatch.quantity} units={materialBatch.units} />
            </p>
          </OverlayTrigger>}

        {materialBatch &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">Batch Usage Cycles</Tooltip>}
          >
            <p className="labelStyle"><FontAwesomeIcon icon={faRecycle} className="mr-1" /> {materialBatch.usage_cycles}
            </p>
          </OverlayTrigger>}

        {materialLot &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">Material Lot</Tooltip>}
          >
            <p className="labelStyle"><FontAwesomeIcon icon={faArchive} className="mr-1" /> {materialLot.name}</p>
          </OverlayTrigger>}
      </div>
    </div>
  );
};

MaterialChips.propTypes = {
  material: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  materialLot: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  materialBatch: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    usage_cycles: PropTypes.number.isRequired,
    units: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  isMaterialLoading: PropTypes.bool.isRequired,
};

export default MaterialChips;
