import { faExclamationTriangle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import { convertToUserUnits, formatTwoDecimalsNumber } from 'src/utils/conversions';
import { formatConvertedUnits } from 'src/utils/ui';
import { formatDynamicPercentage } from 'src/utils/validation';

const ContainerQuantityInput = ({
  container,
  batchUnits,
  convertedBatchUnits,
  isConverted,
  initialQuantity,
  setRemainingQuantityHandler,
  removeContainerHandler,
  containersLength,
  isNotSupportedByMaterials,
}) => {
  const { quantity: convertedMaxQuantity } = useMemo(
    () => convertToUserUnits(container.maxQuantity, batchUnits),
    [container.maxQuantity, batchUnits]
  );
  const { quantity: convertedQuantity } = useMemo(
    () => convertToUserUnits(container.quantity, batchUnits),
    [container.quantity, batchUnits]
  );

  const {
    selectedAmountBase,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
  } = useSelectedAmountHandler(
    container.maxQuantity,
    batchUnits,
    convertedMaxQuantity,
    convertedBatchUnits,
    container.quantity, // Initial Base Value (for Range)
    convertedQuantity // Initial Converted Value (for Input)
  );

  useEffect(() => {
    setRemainingQuantityHandler(container.uri, selectedAmountBase);
  }, [selectedAmountBase]);

  const containerRemainingPercentage = formatDynamicPercentage(selectedAmountBase, initialQuantity);

  return (
    <>
      <p className='mb0 d-flex align-items-center justify-content-center'>
        <strong>{container.name}</strong>
        {isNotSupportedByMaterials && (
          <FontAwesomeIcon icon={faExclamationTriangle} className='d-block ml-2 warning-color' />
        )}
      </p>
      <div className='alert'>
        <div
          className={`d-flex align-items-center ${
            containersLength > 1 ? 'justify-content-between' : 'justify-content-center'
          }`}
        >
          <div className='mb10 d-flex align-items-center'>
            <input
              name='remainingQuantity'
              min='0'
              max={convertedMaxQuantity}
              value={selectedAmountConverted}
              className='unload-hopper-input'
              type='number'
              placeholder='Enter Remaining Quantity'
              onChange={handleSelectedAmountChange}
            />
            <div className='unload-hopper-input-input-text'>
              <p className='mb0 mr5'>{convertedBatchUnits}</p>&nbsp;
              <p className={`mb0 ${selectedAmountBase < 0 ? 'text-danger' : ''}`}>
                ({containerRemainingPercentage}%)
              </p>
            </div>
          </div>
          {containersLength > 1 && (
            <button
              type='button'
              className='btn btn-default btn-action'
              onClick={() => removeContainerHandler(container.uri)}
            >
              <FontAwesomeIcon color='#F31414' size='2x' icon={faXmark} />
            </button>
          )}
        </div>
        {containersLength <= 1 && (
          <div className='d-flex align-items-center justify-content-center mt15'>
            <div>
              <div className='mb5'>0 ({batchUnits})</div>
              <div>{isConverted && formatConvertedUnits(0, convertedBatchUnits)}</div>
            </div>
            <div className='position-relative'>
              <RangeInputConversionTooltip
                className='rangeInputAbsolute'
                visible={isConverted}
                defaultUnits={batchUnits || ''}
                convertedUnits={convertedBatchUnits}
              />
              <input
                name='remainingQuantityRange'
                min='0'
                className='unload-hopper-input-slider'
                step='0.01'
                max={container.maxQuantity}
                type='range'
                value={selectedAmountBase}
                onChange={e => handleRangeChange(e.target.value)}
              />
            </div>
            <div>
              <div className='mb5'>
                {formatTwoDecimalsNumber(container.maxQuantity)} ({batchUnits})
              </div>
              <div>
                {isConverted && formatConvertedUnits(convertedMaxQuantity, convertedBatchUnits)}
              </div>
            </div>
          </div>
        )}
      </div>
      <hr className='flex-grow-1' />
    </>
  );
};

ContainerQuantityInput.propTypes = {
  container: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    maxQuantity: PropTypes.number.isRequired,
  }).isRequired,
  batchUnits: PropTypes.string.isRequired,
  convertedBatchUnits: PropTypes.string.isRequired,
  isConverted: PropTypes.bool.isRequired,
  initialQuantity: PropTypes.number.isRequired,
  setRemainingQuantityHandler: PropTypes.func.isRequired,
  removeContainerHandler: PropTypes.func.isRequired,
  containersLength: PropTypes.number.isRequired,
  isNotSupportedByMaterials: PropTypes.bool.isRequired,
};

export default ContainerQuantityInput;
