import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MEASUREMENT_SYSTEM } from 'src/utils/constants';

const MeasurementSystemButton = ({ system, currentSystem, onChange }) => {
  const displayNames = {
    [MEASUREMENT_SYSTEM.IMPERIAL]: 'Imperial',
    [MEASUREMENT_SYSTEM.METRIC]: 'Metric',
    [MEASUREMENT_SYSTEM.CUSTOM]: 'Custom',
  };

  const handleClick = () => {
    onChange(system);
  };

  return (
    <Button
      name='units'
      id={system}
      className='spacer-right'
      type='button'
      variant={currentSystem === system ? 'primary' : 'default'}
      onClick={handleClick}
    >
      {displayNames[system]}
    </Button>
  );
};

MeasurementSystemButton.propTypes = {
  system: PropTypes.string.isRequired,
  currentSystem: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MeasurementSystemButton;
