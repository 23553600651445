import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConvertedUnits from 'src/components/ConvertedUnits';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { handleConvertedQuantityChange, validateMultipleZeros } from 'src/utils/validation';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';


const MachineUnloadChamber = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [batch, setBatch] = useState(null);
  const quantityInMachine = batch?.at_machine ? batch.quantity : 0;
  const [subLocation, setSubLocation] = useState(undefined);

  const [unloadedQuantityBase, setUnloadedQuantityBase] = useState(0);
  const [unloadedQuantityConverted, setUnloadedQuantityConverted] = useState('');

  const navigate = useNavigate();

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json();
      setBatch(batch);
      setSubLocation(subLocation);
      
      const quantityInMachine = batch.at_machine ? batch.quantity : 0;
      setUnloadedQuantityBase(quantityInMachine);

      // Convert the initial unloaded quantity to user's preferred units
      const conversionResult = convertToUserUnits(quantityInMachine, batch.units);
      setUnloadedQuantityConverted(conversionResult.quantity);

    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => void getInitialData(), [batchUUID]);

  // Convert batch quantity to user's preferred units
  const { quantity: convertedBatchQuantity, units: convertedBatchUnits } =
    useMemo(() => {
      if (batch) {
        return convertToUserUnits(batch.quantity, batch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [batch]);

  const handleUnloadedQuantityChange = (e) => {
    handleConvertedQuantityChange({
      e,
      setBaseQuantity: setUnloadedQuantityBase,
      setConvertedQuantity: setUnloadedQuantityConverted,
      batchQuantity: batch.quantity,
      batchUnits: batch.units,
      convertedBatchQuantity,
      convertedBatchUnits,
      validateMultipleZeros,
    });
  };


  const remainingQuantity = batch ? batch.quantity - unloadedQuantityBase : 0;
  const unloadedQuantityPercentage =
    batch && batch.quantity
      ? Math.round((unloadedQuantityBase / batch.quantity) * 100)
      : 0;


  const onActionUnloadedChamber = async (batchUri) => {
    let actionResult = null;
    try {
      const payload = {
        /* eslint-disable camelcase */
        metadata: {
          unload_amount: unloadedQuantityBase,
          unload_type: 'consumed',
        },
        source_batch: batchUri,
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
        /* eslint-enable camelcase */
      };
      setSubmitting(true);
      actionResult = await materialActionRequest(payload).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    const { metadata: { resulting_batch: unloadedBatch } } = actionResult;

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(unloadedBatch) },
      {
        action: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
        batch: getUuid(unloadedBatch),
        quantity: unloadedQuantityBase,
        remainingQuantity,
        subLocation: subLocation.name,
        printerUri: batch.at_machine,
      }));

    return new Promise(() => {
    });
  };

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  return (
    <ActionPage
      id={batchUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER}
      isLoading={isLoading}
    >
      {batch &&
        <>
          <div className="alert alert-warning" role="alert">
            <b>Amount of material originally loaded into machine:</b>&nbsp;
            <ConvertedUnits quantity={quantityInMachine} units={batch.units} />
          </div>
          <div className="alert">
            <div>
              <label>
                Record the weight of the build as extracted from the build chamber
                (this may include trapped powder). If you cannot weigh the build,
                please enter an estimated build weight based on the volume of the build and the used material&apos;s
                density.
              </label>
              <input
                name="remainingQuantity"
                min="0"
                max={quantityInMachine}
                type="number"
                value={unloadedQuantityConverted}
                onChange={handleUnloadedQuantityChange}
              />
              &nbsp;<span>{convertedBatchUnits} ({unloadedQuantityPercentage}%)</span>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary btn-block"
            disabled={!batch.at_machine || isSubmitting}
            onClick={() => onActionUnloadedChamber(batch.uri)}
          >
            Save
          </button>
        </>}

    </ActionPage>
  );
};

MachineUnloadChamber.propTypes = {
  user: userPropType,
};

MachineUnloadChamber.defaultProps = {
  user: null,
};

export default MachineUnloadChamber;
